@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Black/Inter-Black.eot");
    src: url("../fonts/inter/Black/Inter-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/inter/Black/Inter-Black.otf") format("otf"),
        url("../fonts/inter/Black/Inter-Black.svg#Inter-Black") format("svg"),
        url("../fonts/inter/Black/Inter-Black.woff") format("woff"),
        url("../fonts/inter/Black/Inter-Black.ttf") format("truetype"),
        url("../fonts/inter/Black/Inter-Black.woff2") format("woff2");
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/ExtraBold/Inter-ExtraBold.eot");
    src: url("../fonts/inter/ExtraBold/Inter-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/inter/ExtraBold/Inter-ExtraBold.otf") format("otf"),
        url("../fonts/inter/ExtraBold/Inter-ExtraBold.svg#Inter-ExtraBold") format("svg"),
        url("../fonts/inter/ExtraBold/Inter-ExtraBold.ttf") format("truetype"),
        url("../fonts/inter/ExtraBold/Inter-ExtraBold.woff") format("woff"),
        url("../fonts/inter/ExtraBold/Inter-ExtraBold.woff2") format("woff2");
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Bold/Inter-Bold.eot");
    src: url("../fonts/inter/Bold/Inter-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/inter/Bold/Inter-Bold.otf") format("otf"),
        url("../fonts/inter/Bold/Inter-Bold.svg#Inter-Bold") format("svg"),
        url("../fonts/inter/Bold/Inter-Bold.woff") format("woff"),
        url("../fonts/inter/Bold/Inter-Bold.ttf") format("truetype"),
        url("../fonts/inter/Bold/Inter-Bold.woff2") format("woff2");
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/SemiBold/Inter-SemiBold.eot");
    src: url("../fonts/inter/SemiBold/Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/inter/SemiBold/Inter-SemiBold.otf") format("otf"),
        url("../fonts/inter/SemiBold/Inter-SemiBold.svg#Inter-SemiBold") format("svg"),
        url("../fonts/inter/SemiBold/Inter-SemiBold.ttf") format("truetype"),
        url("../fonts/inter/SemiBold/Inter-SemiBold.woff") format("woff"),
        url("../fonts/inter/SemiBold/Inter-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Medium/Inter-Medium.eot");
    src: url("../fonts/inter/Medium/Inter-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/inter/Medium/Inter-Medium.otf") format("otf"),
        url("../fonts/inter/Medium/Inter-Medium.svg#Inter-Medium") format("svg"),
        url("../fonts/inter/Medium/Inter-Medium.ttf") format("truetype"),
        url("../fonts/inter/Medium/Inter-Medium.woff2") format("woff2"),
        url("../fonts/inter/Medium/Inter-Medium.woff") format("woff");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Regular/Inter-Regular.eot");
    src: url("../fonts/inter/Regular/Inter-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/inter/Regular/Inter-Regular.otf") format("otf"),
        url("../fonts/inter/Regular/Inter-Regular.svg#Inter-Regular") format("svg"),
        url("../fonts/inter/Regular/Inter-Regular.ttf") format("truetype"),
        url("../fonts/inter/Regular/Inter-Regular.woff") format("woff"),
        url("../fonts/inter/Regular/Inter-Regular.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Light/Inter-Light.eot");
    src: url("../fonts/inter/Light/Inter-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/inter/Light/Inter-Light.otf") format("otf"),
        url("../fonts/inter/Light/Inter-Light.ttf") format("truetype"),
        url("../fonts/inter/Light/Inter-Light.svg#Inter-Light") format("svg"),
        url("../fonts/inter/Light/Inter-Light.woff") format("woff"),
        url("../fonts/inter/Light/Inter-Light.woff2") format("woff2");
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/ExtraLight/Inter-ExtraLight.eot");
    src: url("../fonts/inter/ExtraLight/Inter-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("../fonts/inter/ExtraLight/Inter-ExtraLight.woff") format("woff"),
        url("../fonts/inter/ExtraLight/Inter-ExtraLight.ttf") format("truetype"),
        url("../fonts/inter/ExtraLight/Inter-ExtraLight.svg#Inter-ExtraLight") format("svg"),
        url("../fonts/inter/ExtraLight/Inter-ExtraLight.otf") format("otf"),
        url("../fonts/inter/ExtraLight/Inter-ExtraLight.woff2") format("woff2");
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Thin/Inter-Thin.eot");
    src: url("../fonts/inter/Thin/Inter-Thin.eot?#iefix") format("embedded-opentype"),
        url("../fonts/inter/Thin/Inter-Thin.woff") format("woff"),
        url("../fonts/inter/Thin/Inter-Thin.woff2") format("woff2"),
        url("../fonts/inter/Thin/Inter-Thin.ttf") format("truetype"),
        url("../fonts/inter/Thin/Inter-Thin.otf") format("otf"),
        url("../fonts/inter/Thin/Inter-Thin.svg#Inter-Thin") format("svg");
    font-weight: 100;
    font-display: swap;
}