@import "../../assets/scss/includes-components";

.pw-simple-card-container {
  border: rem(1) solid map-get($neutral, 90);
  border-radius: rem(8);
  height: 100%;
  background: map-get($neutral, 100);

  .pw-container-image {
    width: auto;
    height: rem(212);
    border-radius: rem(8);
    position: relative;

    .pw-simple-card-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: rem(8);
    }

    .pw-simple-card-note {
      padding-bottom: rem(16);
      text-align: center;
    }

    @include copyright-content;
  }

  .pw-simple-card-content {
    padding: rem(16);
    border-bottom-left-radius: rem(8);
    border-bottom-right-radius: rem(8);

    .pw-simple-card-description {
      margin-bottom: rem(8);
    }

    .pw-simple-card-cta {
      @include font-size-line-weight(14, 14, 600);
      @include link-underline();
      color: map-get($foundation, primary);
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      padding: rem(12) 0;
      width: max-content;

      &:hover,
      &:focus,
      &:focus-visible {
        &::after {
          width: 100%;
        }
      }

      .pw-simple-card-icon {
        @include font-size-line-weight(14, 14);
        margin-right: rem(4);
      }
    }
  }
}
