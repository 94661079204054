@import "../../assets/scss/includes-components";

.pw-highlighted {
    background-color: map-get($background, ice-blue-97);
    color: map-get($neutral, 10);
    padding: rem(16) rem(8);
    border-radius: rem(8);
    margin-bottom: 0;

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
        padding: rem(24);
    }

    .pw-br-highlighted {
        @media screen and (max-width: calc(#{rem(map-get($breakpoints, md))} - #{rem(1)})) {
            display: none;
        }
    }
}