@import "../../assets/scss/includes-components";

.pw-information-module-container {
  background-color: map-get($status, 10);
  padding: rem(8);
  border-radius: rem(8);
  
  @media screen and (min-width: rem(map-get($breakpoints, lg))) {
    width: 75%;
    padding: rem(8) rem(12);
  }

  &:not(:last-child) {
      margin-bottom: rem(16);
  }

  .pw-information-module-box {
    display: flex;
    align-items: center;

    .pw-information-module-image-container {
      width: rem(56);
      height: rem(56);
      background: map-get($neutral, 100);
      border-radius: 100%;
      padding: rem(4) 0;
      margin-right: rem(16);
      flex: 0 0 auto;

      @media screen and (min-width: rem(map-get($breakpoints, lg))) {
        margin-right: rem(32);
      }

      .pw-information-module-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .pw-information-module-text {
      margin: 0;
    }
  }
}