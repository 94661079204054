// Define breakpoints for Bootstrap columns
$grid-breakpoints: (
    xs: 0,
    xsm: 375px,
    sm: 768px,
    md: 1024px,
    lg: 1280px,
    xl: 1366px,
);

$grid-gutter-width: 24px;

@import "./node_modules/bootstrap/scss/bootstrap.scss";
@import "./assets/scss/includes";

html,
body {
    overflow-x: hidden;
    font-family: 'Inter';
    @include font-size-line-weight(16, 24);
    color: map-get($neutral, 10);

    &.is-modal-open {
        overflow: hidden;
    }
}

.pw-container {
    @include pw-container;
}

.pw-txt-none {
    text-decoration: none;
}