
@import "../../assets/scss/includes-components";

.pw-container-faq {
  position: relative;
  color: map-get($neutral, 10);
  padding-bottom: rem(40);

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    padding-bottom: rem(104);
    margin: 0;
    position: relative;
  }

  .pw-image-faq {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .pw-title-faq-section,
  .pw-subtitle-faq-section {
    margin-bottom: 0;

    @media screen and (max-width: rem(map-get($breakpoints, md))) {
      text-align: center;
    }
  }

  .pw-title-faq-section {
    text-transform: uppercase;
    margin-top: rem(24);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      margin-top: rem(48);
      @include font-size-line-weight(38, 46);
    }
  }

  .pw-subtitle-faq-section {
    margin-top: rem(8);

    &.paragraph {
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        @include font-size-line-weight(20, 30);
      }
    }
  }

  .pw-title-faq {
    margin: rem(32) 0 rem(20);

    &:first-of-type {
      margin-top: rem(40);
    }
  }

  .paragraph-bold-small {
    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      @include font-size-line-weight(24, 28);
    }
    text-align: left;
  }

  .pw-button-faq-section {
    margin-top: rem(20);
  }

  .pw-help-center-container {
    .pw-help-center-title {
      margin: rem(40) 0 rem(20);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        margin-top: rem(72);
      }
    }
  
    .pw-box-assistance-help-center {
      margin-bottom: rem(16);
  
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        margin-bottom: 0;
      }
    }
  }
}

