@import "../../assets/scss/includes-components";

.pw-container-logout {
    .pw-modal-content {
        height: auto;

        .logout-description {
            color: map-get($neutral, 30);
            margin-bottom: 0;

            &.paragraph-semi-bold-small {
                @include font-size-line-weight(20, 26);
            }
        }
    }
}