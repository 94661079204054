@import "../../assets/scss/includes-components";

.pw-container-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: rem(40);
    padding-bottom: rem(48);

    @media screen and (min-width: rem(map-get($breakpoints, sm))) {
        padding-top: rem(64);
        padding-bottom: rem(64);
    }

    .pw-summary-title {
        margin: 0;
        color: map-get($neutral, 10);
    }

    .container-info {
        width: 100%;

        @media screen and (min-width: rem(map-get($breakpoints, lg))) {
            padding: 0 calc(8.33333333% - rem(16));
        }

        .info-box-wrapper {
            position: relative;
            gap: rem(16);
            padding-top: rem(40);

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                padding-top: rem(56);
            }

            .pw-info-wrapper-center {
                justify-content: space-around;

                .pw-info-box {
                    .paragraph-xs-bold,
                    .paragraph-xs {
                        @media screen and (min-width: rem(map-get($breakpoints, md))) {
                            @include font-size-line-weight(20, 30);
                        }
                    }
                }

                .pw-info-box-text {
                    padding-top: rem(12);
                    margin: 0;
                    color: map-get($neutral, 10);

                    @media screen and (min-width: rem(map-get($breakpoints, md))) {
                        padding-top: rem(28);
                    }

                    .pw-br-info-box,
                    .pw-br-info-box-two {
                        @media screen and (max-width: calc(#{rem(map-get($breakpoints, xsm))} - #{rem(1)})) {
                            display: none;
                        }
                    }

                    .pw-br-info-box-two {
                        @media screen and (min-width: rem(map-get($breakpoints, md))) {
                            display: none;
                        }
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: rem(80);
                    left: 16.66666667%;
                    right: 16.66666667%;
                    border-top: rem(2) dashed map-get($background, secondary-90);
                    z-index: -1;
            
                    @media screen and (min-width: rem(map-get($breakpoints, sm))) {
                        border-width: rem(4);
                    }
            
                    @media screen and (min-width: rem(map-get($breakpoints, md))) {
                        top: calc(rem(80) + rem(56));
                    }
                }
            }
        }
    }
}