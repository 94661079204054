@import "../../assets/scss/includes-components";

.pw-modal-unsubscribe-confirmed-container {
  .pw-modal-content {
    height: auto;
  }
}

.pw-modal-unsubscribe-confirmed-container,
.pw-modal-unsubscribe-completed-container {
  .pw-modal-content {
    @media screen and (min-width: rem(map-get($breakpoints, sm))) {
      width: 50%;
      height: auto;
    }
  }

  .pw-cannot-partecipate-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;

    @media screen and (min-width: rem(map-get($breakpoints, sm))) {
       min-height: auto;
    }
  }
}