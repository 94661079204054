@import "../../assets/scss/includes-components";

.pw-rich-link {
    @include link-underline;

    &:hover,
    &:focus,
    &:focus-visible {
        &::after {
            width: 100%;
        }
    }
}
