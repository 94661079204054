@import "../../assets/scss/includes-components";

.pw-info-box {
    .pw-info-box-container-image {
        border-radius: 100%;
        width: rem(80);
        height: rem(80);
        background: linear-gradient(180deg, #ECEDFE 40%, #F6F8FF 100%);
        padding: rem(4);
        margin: 0 auto;

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            width: rem(160);
            height: rem(160);
        }

        .pw-info-box-image {
            width: auto;
            height: 100%;
        }
    }
}