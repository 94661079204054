@import "../../assets/scss/includes-components";

.pw-box-assistance-container {
  border: rem(1) solid map-get($neutral, 90);
  border-radius: rem(8);
  padding: rem(24);
  color: map-get($neutral, 10);
  height: 100%;

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    box-shadow: rem(2) rem(2) rem(8) rem(8) rgba(0, 0, 0, 0.03);
    border: none;
  }

  .pw-box-assistance-content {
    align-items: center;

    .paragraph-semi-bold {
      font-weight: 700;
      margin: 0;
    }

    .pw-box-assistance-title {
      margin-bottom: rem(16);
    }

    .pw-box-assistance-subtitle {
      margin-bottom: rem(8);
    }

    .pw-box-assistance-action {
      display: flex;
      align-items: center;

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        justify-content: center;
      }

      &:hover {
        .pw-box-assistance-icon,
        .pw-box-assistance-cta {
          color: map-get($status, 20);
        }
      }

      .pw-box-assistance-icon,
      .pw-box-assistance-cta {
        color: map-get($foundation, primary);
      }

      .pw-box-assistance-cta {
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        .pw-box-assistance-cta-label {
          text-decoration: underline
        }
      }

      .pw-box-assistance-icon {
        @include font-size-line-weight(24, 24);
        margin-right: rem(4);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          border-left: rem(1) solid map-get($neutral, 90);
          padding-left: rem(16);
        }
      }
    }
  }
}