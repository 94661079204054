@font-face {
  font-family: 'Pay-and-win';
  src:  url('../fonts/icons/Pay-and-win.eot?d6kewa');
  src:  url('../fonts/icons/Pay-and-win.eot?d6kewa#iefix') format('embedded-opentype'),
    url('../fonts/icons/Pay-and-win.ttf?d6kewa') format('truetype'),
    url('../fonts/icons/Pay-and-win.woff?d6kewa') format('woff'),
    url('../fonts/icons/Pay-and-win.svg?d6kewa#Pay-and-win') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-pw"], [class*=" icon-pw"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Pay-and-win' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pwCritical:before {
  content: "\e907";
}
.icon-pwgo-ahead:before {
  content: "\e912";
}
.icon-pwchevron-right:before {
  content: "\e90d";
}
.icon-pwchevron-left:before {
  content: "\e90e";
}
.icon-pwgo-back:before {
  content: "\e910";
}
.icon-pwphone:before {
  content: "\e908";
}
.icon-pwmail:before {
  content: "\e90b";
}
.icon-pwminus:before {
  content: "\e909";
}
.icon-pwPath:before {
  content: "\e90c";
}
.icon-pwplus:before {
  content: "\e90a";
}
.icon-pwcheckmark:before {
  content: "\e906";
}
.icon-pwarrow-left:before {
  content: "\e905";
}
.icon-pwx:before {
  content: "\e902";
}
.icon-pwtwitter:before {
  content: "\e902";
}
.icon-pwsocial-yt:before {
  content: "\e911";
}
.icon-pwsocial-instagram:before {
  content: "\e90f";
}
.icon-pwLinkedin:before {
  content: "\e903";
}
.icon-pwFacebook:before {
  content: "\e904";
}
.icon-pwClose:before {
  content: "\e900";
}
.icon-pwHelp:before {
  content: "\e901";
}