@import "../../assets/scss/includes-components";

.pw-claim-prize {
  color: map-get($neutral, 10);

  .pw-simple-content-image {
    @media screen and (max-width: rem(map-get($breakpoints, sm))) {
      margin-bottom: rem(8);
    }
  }

  .pw-claim-prize-description {
    margin-bottom: rem(24);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      margin-bottom: rem(16);
    }
  }

  .pw-simple-content {
    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      text-align: left;
    }

    .pw-simple-content-body {
      margin: rem(24) 0;

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        justify-content: center;
        display: flex;
        margin: rem(40) 0;
      }

      .pw-simple-content-title {
        margin-top: 0;
      }

      .pw-simple-content-info {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          width: 50%;
        }
      }
    }
  }
}