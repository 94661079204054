@import "../includes-components";

.pw-container-page-not-found {
  display: flex;
  position: relative;
  background-image: url("../../../assets/images/hero-image.webp");
  @include centered-background;
  color: map-get($neutral, 100);
  text-align: center;
  align-items: center;

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    min-height: rem(700);
    align-items: center;
    background-image: url("../../../assets/images/notFoundDesktop.webp");
  }

  .pw-page-not-found-hero-shadow {
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 50.16%,
      rgba(0, 0, 0, 0.75) 100%
    );
    width: 100%;
    height: 100%;

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      background: none;
    }
  }

  .pw-page-not-found-title,
  .pw-page-not-found-logo,
  .pw-page-not-found-subtitle,
  .pw-page-not-found-description {
    margin-bottom: 0;
  }

  .pw-page-not-found-title {
    margin-top: rem(100);
    line-height: rem(44);
    color: map-get($foundation, secondary);
    @include font-size-line-weight(40, 40, 700);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      @include font-size-line-weight(64, 64);
      margin-top: rem(0);
    }
  }

  .pw-page-not-found-logo {
    margin-top: rem(24);
    @include font-size-line-weight(14, 14, 700);
    text-transform: uppercase;

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      @include font-size-line-weight(24, 24);
    }

    .pw-page-not-found-logo-yellow {
      color: map-get($foundation, secondary);
    }
  }

  .pw-page-not-found-subtitle {
    margin-top: rem(16);
    @include font-size-line-weight(32, 32, 700);
    text-transform: uppercase;
    letter-spacing: rem(-2);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      @include font-size-line-weight(64, 64);
    }
  }

  .pw-page-not-found-description {
    margin-top: rem(8);
    padding: 0 rem(24);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      @include font-size-line-weight(16, 20);
      margin-top: rem(16);
    }
  }

  .pw-page-not-found-button {
    width: 100%;
    margin: rem(24) 0;

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      width: auto;
      margin-bottom: 0;
    }
  }
}
