@import "../../assets/scss/includes-components";

.pw-experience-award-container {
  padding: rem(8) 0 rem(24);

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    padding: rem(80) 0 rem(48);
  }

  .pw-title-experience-award {
    margin-bottom: rem(24);
    @include font-size-line-weight(24, 30, 700);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      margin-bottom: rem(16);
      @include font-size-line-weight(28, 34);
    }
  }

  .pw-experience-award-description {
    margin: rem(48) 0;

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      margin: 0 0 rem(40);
    }

    &.paragraph {
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        line-height: rem(20);
      }
    }
  }

  .pw-container-detail-experience {
    margin-top: rem(128);

    .pw-title-detail-experience {
      @include font-size-line-weight(24, 32, 700);
      margin-bottom: rem(24);
    }
  }
}