@import "../../assets/scss/includes-components";

.pw-digital-award-section {
  padding: rem(40) 0 rem(24);
  background-color: map-get($background, ice-blue-97);

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    padding: rem(80) 0;
  }

  .pw-digital-award-section-title {
    margin-bottom: rem(24);
    @include font-size-line-weight(20, 24, 700);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      @include font-size-line-weight(24, 30);
    }

    .pw-br-digital-award-section {
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        display: none;
      }
    }
  }

  .pw-simple-card-container {
    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      display: flex;
      max-height: rem(276);
    }

    .pw-container-image {
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        width: 50%;
        height: auto;
        flex: 0 0 auto;
      }
    }

    .pw-simple-card-content {
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: rem(56) rem(16) rem(56) rem(44);
        padding: 0;
        width: 50%;
      }

      .pw-simple-card-note,
      .pw-simple-card-title {
        margin-bottom: 0;
      }

      .pw-simple-card-title {
        margin-top: rem(16);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          margin-top: 0;
        }
      }

      .pw-simple-card-note {
        margin-top: rem(-8);
        text-align: center;

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          order: 1;
          margin-top: rem(0);
          text-align: initial;
          margin-top: rem(16);
        }
      }

      .pw-simple-card-description {
        margin: rem(8) 0;

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          line-height: rem(20);
        }
      }

      .pw-simple-card-cta {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          @include font-size-line-weight(16, 20);
        }
      }
    }
  }
}
