@import "../../assets/scss/includes-components";

.pw-digital-award-container {
  margin: rem(8) 0 rem(24);

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    margin: rem(80) 0;
  }

  .pw-digital-award-content {
    .pw-container-image-digital-award {
      .pw-digital-award-title {
        margin-bottom: rem(24);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          margin-bottom: rem(16);
        }
      }

      .pw-content-image-digital-award {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          margin-right: rem(56);
        }

        .pw-digital-award-restrictions {
          margin-bottom: rem(16);
          text-align: center;
          @include font-size-line-weight(10, 12, 400);

          @media screen and (min-width: rem(map-get($breakpoints, md))) {
            margin-bottom: 0;
          }
        }

        .sub-paragraph {
          font-weight: 600;
        }

        .pw-digital-award-image {
          width: 100%;
          margin-bottom: rem(8);
          border-radius: rem(8);
        }
      }
    }

    .pw-digital-award-subtitle {
      @include font-size-line-weight(14, 18, 600);
    }

    .pw-digital-award-number {
      @include font-size-line-weight(16, 20, 700);
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        @include font-size-line-weight(28, 35);
      }
    }

    .pw-digital-award-number {
      display: inline-block;
      background-color: map-get($neutral, 10);
      color: map-get($neutral, 100);
      padding: rem(8);
      border-radius: rem(8);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        padding: rem(16);
      }

      // &:first-of-type {
        margin-right: rem(8);
      // }
    }
  }

  .pw-title-digital-award {
    margin-bottom: rem(24);
    @include font-size-line-weight(24, 30, 700);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      margin-bottom: rem(16);
      @include font-size-line-weight(28, 34);
    }
  }
}
