@import "../../assets/scss/includes-components";

.pw-courtesy-section {
    text-align: center;
    margin: rem(40) 0 rem(24);
    color: map-get($neutral, 10);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
        margin: rem(80) auto;
    }

    .pw-courtesy-description,
    .pw-courtesy-title {
        margin-bottom: rem(24);
    }

    .pw-courtesy-title {
        font-weight: 600;

        .pw-br-courtesy-title {
            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                display: none
            }
        }
    }

    .pw-courtesy-description {
        @include font-size-line-weight(16, 20, 600);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            @include font-size-line-weight(20, 24);
        }
    }

    .pw-courtesy-button {
        width: 100%;
        padding: rem(20) rem(64);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            width: auto;
        }
    }
}