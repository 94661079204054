@import "../../assets/scss/includes-components.scss";

.pw-footer {
    .legal-notes-container {
        background-color: map-get($neutral, 90);
        padding: rem(12) 0;

        .legal-notes {
            text-align: center;
            margin-bottom: 0;
            color: map-get($neutral, 40);

            .notes-link {
                color: map-get($foundation, primary);
            }
        }
    }

    .social-container {
        background-color: map-get($foundation, secondary);
        padding: rem(24) 0;

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            padding: rem(18) 0;
        }

        .social-content {
            text-align: center;

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                display: flex;
            }

            .logo-poste-link {
                display: inline-block;
                margin-bottom: rem(16);

                @media screen and (min-width: rem(map-get($breakpoints, md))) {
                    margin-bottom: 0;
                    margin-right: rem(32);
                }

                .logo-image {
                    width: auto;
                    height: rem(16);
                    object-fit: contain;

                    @media screen and (min-width: rem(map-get($breakpoints, md))) {
                        height: rem(24);
                    }
                }
            }

            .icon-container {
                text-align: center;

                .single-icon-link {
                    border-radius: 100%;
                    height: rem(30);
                    width: rem(30);
                    color: map-get($neutral, 100);

                    &:not(:last-child) {
                        margin-right: rem(8);
                    }

                    .pw-icon {
                        @include font-size-line-weight(16, 16);
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .footer-description-container {
        background-color: map-get($background, ice-blue-97);
        padding: rem(32) 0 rem(12);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            padding-bottom: rem(32);
        }

        .footer-description-content {
            text-align: center;

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .sustainability-link {
                display: inline-block;
                margin-bottom: rem(36);

                @media screen and (min-width: rem(map-get($breakpoints, md))) {
                    margin-bottom: 0;
                }

                .sustainability-image {
                    object-fit: contain;
                }
            }

            .footer-description {
                color: map-get($neutral, 10);

                @media screen and (min-width: rem(map-get($breakpoints, md))) {
                    margin-bottom: 0;
                }
            }
        }
    }
}