@import "../../assets/scss/includes-components";

.pw-pre-registration-container {
  &,
  .pw-pre-registration-link {
    color: map-get($neutral, 10);
  }

  .pw-pre-registration-link {
    &:hover,
    &:focus,
    &:focus-visible {
      outline: none;
      color: map-get($foundation, primary);
    }
  }

  .pw-pre-registration-image,
  .pw-pre-registration-container-description {
    border-radius: rem(8);
  }

  .pw-pre-registration-image {
    width: 100%;
    height: rem(220);
    object-fit: cover;
  }

  .pw-pre-registration-title {
    padding-top: rem(24);
    margin: 0;
    text-align: center;
  }

  .pw-pre-registration-form {
    .form-error {
      .pw-checkbox-input:invalid + .pw-checkbox-span::before {
        border-color: map-get($feedback, error);
      }
    }

    .pw-pre-registration-container-description {
      margin: rem(24) 0 rem(50);
      padding: rem(16) rem(8);
      background-color: map-get($background, ice-blue-97);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        padding: rem(24);
        margin-bottom: rem(24);
      }

      &.paragraph-small {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          font-size: rem(16);
        }
      }

      .pw-pre-registration-description {
        margin-bottom: rem(32);
      }

      .pw-container-checkbox {
        width: 100%;

        &:first-of-type {
          margin-bottom: rem(16);
        }
      }
    }

    .pw-pre-registration-container-button {
      @media screen and (min-width: rem(map-get($breakpoints, sm))) {
        display: flex;
        justify-content: flex-end;
      }

      .pw-pre-registration-button {
        @include responsive-spacing;
      }

      .pw-pre-registration-buttom-submit {
        padding: rem(18) rem(0);

        @media screen and (min-width: rem(map-get($breakpoints, sm))) {
          padding: rem(18) rem(60);
        }
      }
    }
  }
}