@import "../../assets/scss/includes-components";

.pw-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: map-get($background, modal-primary-opacity);
  padding: rem(52) rem(24);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &.pw-modal-full-width {
    @media screen and (max-width: rem(map-get($breakpoints, sm))) {
      padding: 0;
    }

    .pw-modal-content,
    .pw-modal-scroll-container {
      border-radius: 0;

      @media screen and (min-width: rem(map-get($breakpoints, sm))) {
        border-radius: rem(8);
      }
    }
  }

  .pw-modal-content,
  .pw-modal-scroll-container {
    height: 100%;
  }

  .pw-modal-content {
    background-color: map-get($neutral, 100);
    position: relative;
    width: 100%;
    overflow: auto;
    border-radius: rem(8);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      width: 75%;
    }

    &.pw-pre-registration-height {
      @media screen and (min-width: rem(map-get($breakpoints, xl))) {
        height: auto;
      }

      .pw-pre-registration-container {
        padding: rem(56) 0 rem(24);

        @media screen and (min-width: rem(map-get($breakpoints, xl))) {
          padding-bottom: rem(32);
        }
      }
    }

    &.pw-modal-content-cannot-partecipate {
      height: auto;

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        width: 45%;
      }
    }

    &.pw-container-modal-try-lose {
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        height: auto;
      }
    }

    &.pw-confirmation-message-height {
      height: auto;
    }

    &.pw-container-modal-game-win {
      @media screen and (min-width: rem(map-get($breakpoints, xl))) {
        height: auto;
      }
    }
  }

  .pw-modal-scroll-container {
    overflow: auto;
    padding: 0 rem(16);
    border-radius: rem(8);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      padding: 0 rem(40);
    }

    .pw-thank-you-content {
      padding: 0 rem(12) rem(24);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        padding: 0 rem(40) rem(40);
      }
    }

    .pw-thank-you-page-close-button {
      background: transparent;
    }

    .pw-thank-you-page-close-button {
      color: map-get($neutral, 100);

      &:hover {
        background-color: map-get($neutral, 100);
        color: map-get($foundation, primary);
      }
    }

    .pw-pre-registration-close-button {
      color: map-get($foundation, primary);

      &:hover {
        background-color: map-get($foundation, primary);
        color: map-get($neutral, 100);
      }
    }

    .pw-modal-close-button {
      position: absolute;
      top: rem(16);
      right: rem(16);
    }

    .pw-icon {
      @include font-size-line-weight(24, 24);
    }
  }
}