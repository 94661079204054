@import "../../assets/scss/includes-components";

.pw-play-game-container {
    height: 100%;
    margin: 0 rem(-16);
    background: url(../../assets/images/bg-play-mobile.webp) no-repeat center / cover;

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
        margin: 0 rem(-40);
        background-image: url(../../assets/images/bg-play-desktop.webp);
    }

    .content-common {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .pw-play-game-content {
        @extend .content-common;

        .pw-play-game-lottie {
            margin: auto 0;

            @media screen and (max-height: rem(600)) {
                width: 50%;
            }
        }

        .pw-play-game-text {
            margin-bottom: rem(50);
            color: map-get($neutral, 100);

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                margin-bottom: rem(40);
            }
        }
    }

    .pw-you-win-content {
        @extend .content-common;
        padding: 0 rem(12);

        .pw-you-win-logo {
            text-transform: uppercase;
            color: map-get($neutral, 100);
            margin: rem(32) 0 0;

            .logo-yellow {
                color: map-get($foundation, secondary);
            }

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                margin-top: rem(40);
            }
        }

        .pw-you-win-img-container {
            margin: auto 0;
            text-align: center;
            color: map-get($neutral, 100);

            .pw-you-win-img {
                width: rem(300);
                height: rem(284);

                @media screen and (min-width: rem(map-get($breakpoints, md))) {
                    width: rem(230);
                    height: rem(218);
                }
            }

            .title-one-semi-bold {
                line-height: rem(64);
                margin-bottom: 0;
            }
        }

        .pw-you-win-button {
            margin-bottom: rem(32);
            padding: rem(16) rem(24);

            @media screen and (max-width: calc(#{rem(map-get($breakpoints, md))} - #{rem(1)})) {
                width: 100%;
            }

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                padding: rem(16) rem(56);
            }
        }
    }
}