@import "../../assets/scss/includes-components";

.pw-button-go-back {
    display: inline-flex;
    align-items: center;
    border: none;
    background: transparent;
    color: map-get($neutral, 10);
    padding: 0;
    margin-bottom: rem(24);
    @include font-size-line-weight(14, 24, 600);

    .pw-icon-go-back {
      margin-right: rem(6);
    }

    .pw-button-go-back-label {
      text-decoration: underline;
    }
  }