@import "../includes-components";

.pw-container-home-page {
    .pw-play-discover-section {
        padding-top: rem(148);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            padding-top: rem(128);
        }
    }
}

.iscriviti-iframe {
    height: 200px;
    @media screen and (min-width: 458px) {
        height: 250px;
    }
    @media screen and (min-width: 574px) {
        height: 300px;
    }
    @media screen and (min-width: 686px) {
        height: 350px;
    }
    @media screen and (min-width: 803px) {
        height: 400px;
    }
    @media screen and (min-width: 915px) {
        height: 450px;
    }
    @media screen and (min-width: rem(map-get($breakpoints, md))) {
        height: 360px;
    }
}

.hero-iframe {
    height: 380px;
    @media screen and (min-width: rem(map-get($breakpoints, md))) {
        height: 700px;
    }
}

.prize-iframe-dashboard {
    margin-top: 120px;
    height: 790px;

    @media screen and (min-width: 383px) {
        height: 740px;
    }

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
        height: 700px;
    }

    @media screen and (min-width: 1126px) {
        height: 680px;
    }
}

.prize-iframe {
    height: 790px;

    @media screen and (min-width: 383px) {
        height: 740px;
    }

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
        height: 700px;
    }

    @media screen and (min-width: 1126px) {
        height: 680px;
    }
}