$root-font-size: 16;

@function rem($target, $context: $root-font-size) {
  @return calc($target / $context) * 1rem;
}

@mixin font-size-line-weight($font-size, $line-height, $font-weight: null) {
  font-size: rem($font-size);
  line-height: rem($line-height);

  @if ($font-weight) {
    font-weight: $font-weight;
  }
}

@mixin pw-container {
  position: relative;
  width: 100%;
  max-width: rem(map-get($breakpoints, xl));
  margin: 0 auto;

  @each $size, $value in $gutter-container {
    @media screen and (min-width: rem(map-get($breakpoints, $size))) {
      padding: 0 rem($value);
    }
  }
}

@mixin centered-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin link-underline() {
  color: currentColor;
  transition: 0.3s all ease-in-out;
  position: relative;

  &::after {
    content: "";
    display: block;
    height: rem(2);
    width: 0;
    background-color: currentColor;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    transition: width 0.3s ease-in-out;
  }
}

@mixin responsive-spacing {
  width: 100%;

  @media screen and (min-width: rem(map-get($breakpoints, sm))) {
    width: auto;
  }

  &:first-child {
    margin-bottom: rem(8);

    @media screen and (min-width: rem(map-get($breakpoints, sm))) {
      margin-bottom: 0;
    }
  }

  &:nth-child(2) {
    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      margin-right: rem(24);
    }
  }
}

@mixin unsubscribe-image-width-height {
  width: auto;
  height: rem(140);

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    height: rem(190);
  }
}

@mixin slider-navigator-custom {
  font-family: "Pay-and-win";
  @include font-size-line-weight(24, 24);
  color: map-get($neutral, 100);
  background: rgba(0, 0, 0, 0.4);

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    padding: rem(8);
  }
}

@mixin finished-plays-modal {
  .pw-modal-content {
    height: auto;

    @media screen and (min-width: rem(map-get($breakpoints, sm))) {
      width: 75%;
    }
  }

  .pw-simple-content {
    @media screen and (max-width: calc(#{rem(map-get($breakpoints, xsm))} - #{rem(1)})) {
      padding: rem(24) 0;
    }
  }

  .pw-finished-plays-container {
    height: 100%;

    .pw-button-finished-plays {
      padding: rem(16) rem(64);

      @media screen and (max-width: calc(#{rem(map-get($breakpoints, sm))} - #{rem(1)})) {
        &.pw-simple-content-action-button {
          margin-bottom: 0;
        }
      }
    }
  }
}

@mixin navigation-experience-award-slider {
  display: inline-flex;
  align-items: center;
  padding: rem(8);
  border-radius: 100%;
  background-color: transparent;
  border: rem(1) solid map-get($foundation, primary);
  color: map-get($foundation, primary);

  &:first-child {
    margin-right: rem(16);
  }

  &:disabled {
    background-color: map-get($neutral, 90);
    border-color: map-get($neutral, 90);
    color: map-get($neutral , 60);
  }
}

@mixin rounded-full-width-image {
  border-radius: rem(8);
  width: 100%;

  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    height: rem(376);
  }
}

@mixin copyright-content {
  .pw-simple-card-copyright {
    position: absolute;
    bottom: 0;
    left: 0;
    color: map-get($neutral, 100);
    padding: 0 0 rem(10) rem(12);
  }

  .container-opacity {
    background: map-get($shadow-colors, shadow-background-copyright);
    position: absolute;
    inset: 0;
    border-radius: rem(8);
  }
}