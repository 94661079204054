@import "../../assets/scss/includes-components";

.pw-game-win-experience-award-container {
  height: 100%;

  .pw-simple-content {
    padding: rem(44) 0 rem(24);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      padding: rem(40) 0;
    }

    .pw-simple-content-body {
      margin-bottom: rem(32);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        margin-bottom: rem(24);
      }

      .pw-simple-content-image {
        @include rounded-full-width-image;
      }

      .pw-simple-content-info {
        .paragraph-small {
          @media screen and (min-width: rem(map-get($breakpoints, md))) {
            @include font-size-line-weight(16, 24);
          }
        }

        .pw-simple-content-title {
          margin: rem(48) 0 rem(12);
          @include font-size-line-weight(40, 46, 600);

          @media screen and (min-width: rem(map-get($breakpoints, md))) {
            margin: rem(24) 0 rem(16);
            @include font-size-line-weight(48, 56);
          }
        }

        .pw-simple-content-subtitle {
          margin-bottom: rem(12);
          @include font-size-line-weight(16, 20, 600);

          @media screen and (min-width: rem(map-get($breakpoints, md))) {
            margin-bottom: rem(16);
            @include font-size-line-weight(20, 25);
          }
        }

        .paragraph-small {
          @media screen and (min-width: rem(map-get($breakpoints, md))) {
            @include font-size-line-weight(16, 24);
          }
        }
      }
    }

    .pw-simple-content-action {
      .pw-simple-content-action-button {
        padding: rem(20) 0 rem(20);
  
        @media screen and (min-width: rem(map-get($breakpoints, sm))) {
          padding: rem(20) rem(40);
        }
      }
    }
  }
}