@import "../../assets/scss/includes-components";

.pw-thumbnail-container {
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    padding-right: rem(56); //this padding comes from the difference in the distance between the thumbanail and the content, minus the size of the gutter-container
  }

  .pw-thumbnail-content {
    .pw-thumbnail-big-image,
    .pw-thumbnail-small-image {
      width: 100%;
      border-radius: rem(8);
      overflow: hidden;
      position: relative;
    }

    .pw-single-small-image,
    .pw-single-big-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    .pw-thumbnail-big-image {
      margin-bottom: rem(8);
      height: rem(228);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        height: rem(280);
      }
    }

    .pw-thumbnail-small-images-container {
      display: flex;
      gap: rem(8);

      .pw-thumbnail-small-image {
        height: rem(108);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          height: rem(140);
        }

        .pw-simple-card-copyright {
          padding: 0 0 rem(4) rem(4);

          &.pw-copyright {
            @include font-size-line-weight(4, 6);
          }
        }
      }
    }
  }

  .pw-thumbnail-slider-container {
    display: flex;
    align-items: center;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(rem(24));
    z-index: 5;

    .pw-thumbnail-overlay {
      height: 100%;
      display: flex;
      align-items: center;

      @media screen and (max-width: rem(map-get($breakpoints, xsm))) {
        padding: 0;
      }
    }

    .swiper-button-next {
      &::after {
        @include slider-navigator-custom;
        content: "\e90d";
      }
    }

    .swiper-button-prev {
      &::after {
        @include slider-navigator-custom;
        content: "\e90e";
      }
    }
  }

  .pw-thumbnail-slider {
    position: relative;

    .pw-thumbnail-slider-slide {
      width: 100%;
      height: rem(192);
      object-fit: cover;

      @media screen and (min-width: rem(map-get($breakpoints, sm))) {
        height: rem(448);
      }

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        height: rem(608);
      }
    }
  }

  .pw-close-button-container {
    position: absolute;
    top: rem(8);
    right: rem(8);
    z-index: 5;

    .pw-thumbnail-slider-button-close {
      background-color: transparent;
      color: map-get($neutral, 100);
      flex-direction: row-reverse;
      margin-left: 0;
      @include font-size-line-weight(14, 14, 600);

      .button-span {
        margin-left: 0;
        margin-right: rem(4)
      }

      .pw-icon {
        @include font-size-line-weight(16, 16);
      }
    }
  }

  @include copyright-content;
}