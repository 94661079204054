@import "../../assets/scss/includes.scss";


.pw-loader-container {
    position: fixed;
    z-index: 100;
    inset: 0;
    background: map-get($neutral, 100);
    display: flex;
    justify-content: center;
    align-items: center;

    .pw-loader-dim {
        width: rem(220);
        height: auto;

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            width: rem(250);
        }
    }
}