@import "../../assets/scss/includes-components";

.pw-error-message-subscribe {
  display: inline-flex;
  margin-top: rem(16);

  .pw-error-message-subscribe-icon {
    align-self: baseline;
    background: map-get($feedback, light-red);
    color: map-get($feedback, error);
    border-radius: rem(8);
    padding: rem(4);
    @include font-size-line-weight(24, 24);
    margin-right: rem(12);
  }

  .pw-error-message {
    margin-top: rem(4);
  }
}
