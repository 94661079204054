@import "../../assets/scss/includes-components";

.pw-container-navbar {
    background-color: map-get($foundation, secondary);
    height: rem(64);
    display: flex;
    align-items: center;

    .navbar-main {
        .wrapper-item {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            &,
            .pw-link,
            .pw-button,
            .pw-icon {
                color: map-get($neutral, 10);
            }

            .item {
                &:not(:last-child, .content-logo) {
                    margin-right: rem(8);

                    @media screen and (min-width: rem(map-get($breakpoints, md))) {
                        margin-right: rem(16);
                    }
                }

                .pw-link {
                    position: relative;
                    background-color: transparent;

                    .pw-icon {
                        @include font-size-line-weight(22, 22);
                        margin-right: 0;

                        @media screen and (min-width: rem(map-get($breakpoints, md))) {
                            @include font-size-line-weight(24, 24);
                            margin-right: rem(4);
                        }
                    }

                    &.button-icon-menu {
                        padding: 0;

                        &:hover {
                            color: map-get($status, 20);
                        }

                        &:focus,
                        &:focus-visible {
                            color: map-get($status, 30);
                        }

                        .button-span {
                            @include link-underline();
                            margin-left: 0;

                            @media screen and (max-width: calc(#{rem(map-get($breakpoints, md))} - #{rem(1)})) {
                                display: none;
                            }
                        }

                        &:hover,
                        &:focus,
                        &:focus-visible {
                            .button-span {
                                &::after {
                                    width: 100%;
                                }
                            }
                        }

                        .pw-icon {
                            border-radius: 100%;
                            padding: rem(6); //leave this size to reach the value of 36px, which is the right size required by the design

                            &,
                            &:hover,
                            &:focus,
                            &:focus-visible {
                                background-color: map-get($background, secondary-85);
                            }
                        }
                    }
                }
            }

            .content-logo {
                position: absolute;
                width: 100%;
                top: 50%;
                right: 0;
                transform: translateY(-50%);

                @media screen and (max-width: calc(#{rem(map-get($breakpoints, xsm))} - #{rem(1)})) {
                    left: rem(map-get($gutter-container, xs));
                }

                @media screen and (min-width: calc(#{rem(map-get($breakpoints, xsm))} - #{rem(1)})) {
                    text-align: center;
                    left: 0;
                }

                .pw-logo {
                    display: inline-block;

                    .pw-image {
                        width: auto;
                        height: rem(16);

                        @media screen and (min-width: rem(map-get($breakpoints, md))) {
                            height: rem(24);
                        }
                    }
                }
            }
        }
    }

    .navbar-popup {
        background-color: map-get($foundation, secondary);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: rem(64);
        transform: translateY(-100%);
        transition: all 0.3s ease-in-out;
        z-index: 10;

        &.show {
            transform: translateY(0);
        }

        .navbar-popup-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            .navbar-popup-title {
                margin: 0;
            }
        }
    }
}