@import "../../assets/scss/includes-components";

.pw-confirmation-message-container {
    height: 100%;

    .pw-simple-content-button-confirmation-message {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            padding: rem(18) rem(52);
        }
    }
}
