@import "../../assets/scss/includes-components";

.pw-container-checkbox {
    position: relative;
    display: inline-block;

    &:hover .pw-checkbox-input:not(:disabled) {
        opacity: 10%;
        background-color: map-get($neutral, 10);
        transition: 0.3s;
    }

    .pw-checkbox-input {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        width: 0;
        height: 0;
        opacity: 0;
        transition: opacity 0.3s, transform 0.2s;

        &:checked + .pw-focus-checkbox + .pw-checkbox-span:before  {
            background-color: map-get($foundation, primary);
        }

        &:checked + .pw-focus-checkbox + .pw-checkbox-span:after  {
            opacity: 1;
        }

        &:active {
            opacity: 1;
            transform: scale(0);
        }

        &:disabled + .pw-focus-checkbox + .pw-checkbox-span::before {
            border-color: map-get($neutral, 40);
            background-color: map-get($neutral, 90);
        }

        &:focus + .pw-focus-checkbox {
            opacity: 1;
            background-color: rgba( map-get($neutral, 10), 0.1);
        }

        &:focus:checked + .pw-focus-checkbox {
            background-color: rgba( map-get($foundation, primary), 0.1);
        }
    }

    .pw-focus-checkbox {
        position: absolute;
        left: rem(-14);
        top: rem(-14);
        border-radius: 50%;
        width: rem(52);
        height: rem(52);
        opacity: 0;
    }

    .pw-checkbox-span {
        display: inline-flex;
        position: relative;
        width: 100%;
        gap: rem(12);
        cursor: pointer;

        &::before {
            content: "";
            display: inline-block;
            border: solid rem(1) map-get($foundation, primary);
            border-radius: rem(4);
            width: rem(24);
            height: rem(24);
            flex: 0 0 auto;
            background-color: map-get($neutral, 100);
            transition: border-color 0.2s, background-color 0.2s;
        }

        &::after {
            content: "\e906";
            font-family: 'Pay-and-win';
            position: absolute;
            opacity: 0;
            color: map-get($neutral, 100);
            top: rem(6);
            left: rem(4);
            @include font-size-line-weight(12, 12);
        }

        .pw-checkbox-label {
            @media screen and (min-width: rem(map-get($breakpoints, sm))) {
                padding-top: rem(2);
            }
        }
    }
}

.form-error {
    .pw-checkbox-input:invalid + .pw-focus-checkbox + .pw-checkbox-span::before {
        border-color: map-get($feedback, error);
    }
}