@import "../../assets/scss/includes-components";

.pw-breadcrumb {
  .pw-breadcrumb-container {
    display: flex;
    align-items: center;
    list-style: none;
    padding-top: rem(8);
    padding-left: 0;
    margin: 0;

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      padding-top: rem(80);
    }

    .pw-breadcrumb-link,
    .pw-breadcrumb-label {
      color: map-get($neutral, 10);
    }

    .pw-accordion-item-btn,
    .pw-breadcrumb-link {
      &:focus {
        outline: none;
      }
    }

    .pw-breadcrumb-label {
      display: inline-block;

      .pw-breadcrumb-icon-arrow {
        @include font-size-line-weight(12, 12);
        margin-right: rem(4);
        color: map-get($neutral, 10);
      }
    }
  }
}