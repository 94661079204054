@import "../../assets/scss/includes-components";

.pw-modal-error {
    position: fixed;
    overflow-y: auto;
    z-index: 100;
    inset: 0;
    background-color: map-get($background, modal-primary-opacity);
    
    @media screen and (min-width: rem(map-get($breakpoints, md))) {
        background-color: map-get($background, modal-secondary-opacity);
    }

    .pw-error-container {
        max-width: rem(696);
        background-color: map-get($neutral, 100);
        border: rem(1) solid map-get($feedback, error);
        border-radius: rem(8);
        border-left: rem(8) solid map-get($feedback, error);
        position: absolute;
        top: rem(80);
        width: calc(100% - rem(32));
        left: 50%;
        transform: translateX(-50%);

        @media screen and (min-width: rem(map-get($breakpoints, sm))) {
            width: 100%;
            top: rem(112);
        }

        .pw-error-content {
            padding: rem(32);
        }

        .pw-error-header {
            display: flex;
            align-items: center;

            .pw-error-icon {
                @include font-size-line-weight(32, 32);
                background-color: map-get($feedback, error);
                color: map-get($neutral, 100);
                border-radius: 100%;
            }

            .pw-error-title {
                margin: 0 0 0 rem(12);
            }
        }

        .pw-error-description {
            margin: rem(16) 0 rem(24);
        }

        .pw-error-title,
        .pw-error-description {
            color: map-get($neutral, 10);
        }

        .pw-error-button {
            padding: rem(16) rem(32);
        }
    }
}