@import "../../assets/scss/includes-components";

.pw-game-win-digital-award-container {
  height: 100%;

  .pw-simple-content {
    .pw-simple-content-body {
      margin-bottom: rem(40);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        margin-bottom: rem(24);
      }

      .pw-simple-content-image {
        @include rounded-full-width-image;
      }

      .pw-simple-content-note {
        @include font-size-line-weight(10, 12, 400);
      }

      .pw-simple-content-title {
        margin: rem(32) 0 rem(12);
        @include font-size-line-weight(40, 46, 600);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          margin: rem(12) 0 rem(16);
          @include font-size-line-weight(48, 56);
        }
      }

      .pw-simple-content-subtitle {
        margin-bottom: rem(12);
        @include font-size-line-weight(16, 20, 600);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          @include font-size-line-weight(20, 25);
        }
      }

      .paragraph-small {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          @include font-size-line-weight(16, 24);
        }
      }
    }

    .pw-simple-content-action {
      .pw-simple-content-action-button {
        padding: rem(24) 0 rem(24);

        @media screen and (min-width: rem(map-get($breakpoints, sm))) {
          padding: rem(20) rem(64);
        }
      }
    }
  }
}
