@import "../../assets/scss/includes-components";

.focus-background-blue-button {
    &:focus,
    &:focus-visible {
        background-color: map-get($status, 30);
    }
}

.hover-focus-color-light {
    color: map-get($neutral, 100);
}

.underline-button {
    @include link-underline;
    border-radius: 0;

    &:hover,
    &:focus,
    &:focus-visible {
        &::after {
            width: 100%;
        }
    }
}

.bg-transparent {
    &,
    &:disabled {
        background-color: transparent;
    }
}

.pw-button {
    border-radius: rem(100);
    border: none;
    padding: rem(18) rem(24);
    outline: none;
    transition: all 0.3s ease-in-out;

    &,
    .button-span {
        display: inline-block;
    }

    &:not(.small) {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            padding: rem(16) rem(24);
        }
    }

    &.small {
        padding: rem(10) rem(24);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            @include font-size-line-weight(14, 16);
        }
    }

    &.blue {
        background-color: map-get($foundation, primary);
        @extend .hover-focus-color-light;

        &:hover {
            background-color: map-get($status, 20);
        }

        &:focus,
        &:focus-visible {
            @extend .focus-background-blue-button;
        }

        &.underline {
            @extend .bg-transparent;

            &:not(:disabled) {
                color: map-get($foundation, primary);

                &:hover {
                    color: map-get($status, 20);
                    background-color: transparent;
                }

                &:focus,
                &:focus-visible {
                    color: map-get($status, 30);
                }

                @extend .underline-button;
            }
        }

        &.blue-border-transparent {
            background-color: transparent;
            color:  map-get($foundation, primary);
            border:  map-get($foundation, primary) rem(1) solid;

           &:not(:disabled) {
                &:hover {
                    background-color: map-get($foundation, primary);
                    color: map-get($neutral, 100 );
                }

                &:focus,
                &:focus-visible {
                    color: map-get($status, 10);
                }
            }

            &:disabled {
                border: none;
            }
        }
    }

    &.white {
        background-color: map-get($neutral, 100);
        color: map-get($foundation, primary);

        &:hover {
            background-color: map-get($status, 20);
        }

        &:focus,
        &:focus-visible {
            @extend .focus-background-blue-button;
        }

        &:hover,
        &:focus,
        &:focus-visible {
            @extend .hover-focus-color-light;
        }

        &.underline {
            @extend .bg-transparent;

            &:not(:disabled) {
                &,
                &:hover,
                &:focus,
                &:focus-visible {
                    color: map-get($neutral, 100);
                    background-color: transparent;
                }

                @extend .underline-button;
            }
        }
    }

    &.blue,
    &.white {
        &:disabled {
            background-color: map-get($neutral, 90);
            color: map-get($neutral, 60);
        }
    }

    // Button icon 
    &.pw-button-icon {
        padding: rem(8);
        display: inline-flex; // remove area from icon
        align-items: center;
        justify-content: center;
        /*
        *  Subtract the 2px margin to keep the icon's height equal to 40,
        * because an icon with a height less than 40 can make it difficult to click.
        */
        margin: rem(-2);
    }

    .pw-icon {
        @include font-size-line-weight(24, 24);
    }

    .pw-icon ~ .button-span {
        margin-left: rem(8);
    }
}