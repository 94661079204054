.button-label {
  font-family: "Inter";
  @include font-size-line-weight(14, 16, 600);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(16, 18);
  }
}

.button-label-md {
  font-family: "Inter";
  @include font-size-line-weight(16, 16, 600);
}

.button-label-upper {
  @extend .button-label;
  text-transform: uppercase;
}

.title-one-bold {
  font-family: "Inter";
  @include font-size-line-weight(40, 50, 700);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(64, 74);
  }
}

.title-one-bold-upper {
  @extend .title-one-bold;
  text-transform: uppercase;
  line-height: rem(40);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    line-height: rem(64);
  }
}

.title-one-semi-bold {
  font-family: "Inter";
  @include font-size-line-weight(64, 74, 600);
}

.title-two-regular {
  font-family: "Inter";
  @include font-size-line-weight(20, 32, 400);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(40, 52);
  }
}

.title-three-bold {
  font-family: "Inter";
  @include font-size-line-weight(32, 38, 700);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(38, 48);
  }
}

.title-four-bold {
  font-family: "Inter";
  @include font-size-line-weight(24, 28, 700);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(32, 38);
  }
}

.paragraph-bold-small {
  font-family: "Inter";
  @include font-size-line-weight(18, 22, 700);
}

.paragraph-semi-bold-xl {
  font-family: "Inter";
  @include font-size-line-weight(32, 40, 600);
}

.paragraph-semi-bold {
  font-family: "Inter";
  @include font-size-line-weight(24, 30, 600);
}

.paragraph-semi-bold-medium {
  font-family: "Inter";
  @include font-size-line-weight(20, 28, 600);
}

.paragraph-semi-bold-small {
  font-family: "Inter";
  @include font-size-line-weight(16, 20, 600);
}

.paragraph-semi-bold-small-upper {
  @extend .paragraph-semi-bold-small;
  line-height: rem(18);
  text-transform: uppercase;
}

.paragraph-semi-bold-small-upper-xs {
  @extend .paragraph-semi-bold-small;
  line-height: rem(16);
  text-transform: uppercase;
}

.paragraph-semi-bold-xs {
  font-family: "Inter";
  @include font-size-line-weight(14, 22, 600);
}

.paragraph-xl {
  font-family: "Inter";
  @include font-size-line-weight(24, 36, 400);
}

.paragraph-xl-upper {
  @extend .paragraph-xl;
  line-height: rem(24);
  text-transform: uppercase;
}

.paragraph-medium {
  font-family: "Inter";
  @include font-size-line-weight(20, 32, 400);
}

.paragraph {
  font-family: "Inter";
  @include font-size-line-weight(16, 24, 400);
}

.paragraph-bold {
  @extend .paragraph;
  font-weight: 700;
}

.paragraph-bold-xs {
  @extend .paragraph;
  font-weight: 700;
}

.paragraph-upper {
  @extend .paragraph;
  line-height: rem(16);
  text-transform: uppercase;
}

.paragraph-small {
  font-family: "Inter";
  @include font-size-line-weight(14, 20, 400);
}

.paragraph-xs {
  font-family: "Inter";
  @include font-size-line-weight(12, 16, 400);
}

.paragraph-xs-bold {
  @extend .paragraph-xs;
  font-weight: 700;
}

.paragraph-xs-upper {
  @extend .paragraph-xs;
  line-height: rem(14);
  text-transform: uppercase;
}

.sub-paragraph {
  font-family: "Inter";
  @include font-size-line-weight(10, 12, 400);
}

.sub-paragraph-line-medium {
  @extend .sub-paragraph;
  line-height: rem(16);
}

.title-five-bold {
  font-family: "Inter";
  @include font-size-line-weight(24, 36, 700);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(32, 40);
  }
}

.title-two-bold {
  font-family: "Inter";
  @include font-size-line-weight(32, 38, 700);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(64, 64);
  }
}

.subtitle-one {
  @extend .paragraph;
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(24, 30);
  }
}

.paragraph-semi-bold-md-upper {
  font-family: "Inter";
  @include font-size-line-weight(16, 18, 600);
  text-transform: uppercase;
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(24, 26);
  }
}

.paragraph-small-bold {
  @extend .paragraph-small;
  font-weight: 700;
}

.subtitle-simple-content {
  @include font-size-line-weight(16, 20, 600);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(20, 25);
  }
}

.paragraph-play-game {
  font-family: 'Inter';
  @include font-size-line-weight(16, 16, 600);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(24, 26);
  }
}

.pw-title-award {
  @include font-size-line-weight(24, 30, 700);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(28, 34);
  }
}

.pw-error-title {
  @include font-size-line-weight(28, 34, 700);
}

.pw-error-message {
  @include font-size-line-weight(14, 24, 400);
}

.pw-copyright {
  @include font-size-line-weight(8, 12, 500);
}

.pw-title-modal-bold {
  @include font-size-line-weight(20, 25, 600);
  @media screen and (min-width: rem(map-get($breakpoints, md))) {
    @include font-size-line-weight(32, 40);
  }
}

.pw-title-box-assistance {
  @include font-size-line-weight(24, 36, 700);
}