@import "../../assets/scss/includes-components";

.pw-cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    min-height: 0;
}

.footer-cookie {
    a {
        @include link-underline;
        text-decoration: none;

        &:hover,
        &:focus,
        &:focus-visible {
            &::after {
                width: 100%;
            }
        }
    }
}