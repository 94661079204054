@import "../../assets/scss/includes.scss";

.pw-hero-section {
    min-height: rem(380);
    display: flex;
    align-items: flex-end;
    position: relative;
    background-image: url("../../assets/images/Heronewcut.png");
    @include centered-background;

    @media screen and (min-width: rem(map-get($breakpoints, sm))) {
        background-image: url("../../assets/images/web.png");
        min-height: rem(700);
        align-items: center;
    }

    &.hero-courtesy-landing {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            min-height: rem(450);
        }
    }

    .hero-shadow {
        position: absolute;
        background: map-get($shadow-colors, shadow-gradient-1);
        width: 100%;
        height: 100%;

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            background: map-get($shadow-colors, shadow-gradient-2);
        }
    }

    .hero-title {
        margin-bottom: rem(8);
        color: map-get($neutral, 100);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            margin-bottom: rem(12);
        }

        .hero-title-span {
            display: inline-block;
            width: 100%;
            color: map-get($foundation, secondary);
        }
    }

    .hero-subtitle {
        color: map-get($foundation, secondary);
        margin-bottom: rem(8);
        padding-right: 16.66666667%;

        @media screen and (min-width: rem(map-get($breakpoints, xsm))) {
            padding-right: 50%;
        }

        @media screen and (min-width: rem(map-get($breakpoints, sm))) {
            padding-right: 0;
        }

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            margin-bottom: rem(12);
        }
    }

    .hero-description {
        display: flex;
        align-items: center;
        color: map-get($neutral, 100);
        margin-bottom: rem(28);
        gap: rem(8);

        @media screen and (min-width: rem(map-get($breakpoints, md))) {
            margin-bottom: rem(28);
        }

        .paragraph-xs-upper {
            margin-bottom: 0;
        }

        .icon-mastercad {
            width: auto;
            height: rem(16);
        }
    }
}