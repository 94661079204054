@import "../../assets/scss/includes-components";

.pw-accordion-item {
  text-align: left;
  border-top: rem(1) solid;
  border-bottom: rem(1) solid;
  border-color: map-get($neutral, 90);

  &:not(:first-of-type) {
    border-top: none;
  }

  &.active .pw-accordion-item-btn::after {
    content: "\e909";
    font-family: "Pay-and-win";
  }

  &.active .pw-accordion-item-btn {
    padding-bottom: rem(20);
  }

  .pw-accordion-item-title {
    margin: 0;

    .paragraph-semi-bold-xs {
      font-weight: 500;
      letter-spacing: rem(1.12);
    }
  }

  .pw-accordion-item-btn {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    padding: rem(12) 0;
    cursor: pointer;
    text-align: left;
    color: map-get($neutral, 10);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      @include font-size-line-weight(16, 28);
    }

    &::after {
      content: "\e90a";
      font-family: "Pay-and-win";
      margin-left: auto;
      @include font-size-line-weight(20, 20);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        @include font-size-line-weight(24, 24);
      }
    }

    &:focus {
      outline: none;
    }
  }

  .pw-accordion-item-container {
    overflow: hidden;

    .pw-accordion-item-content {
      padding-bottom: rem(12);

      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        @include font-size-line-weight(16, 22);
      }

      .pw-faq-link {
        color: map-get($foundation, primary);

        &:hover,
        &:focus,
        &:focus-visible {
          color: map-get($status, 20);
        }
      }
    }

    &.collapsed {
      height: 0;
      transition: height 3s ease-out;
    }
  }
}