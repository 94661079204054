@import "../../assets/scss/includes-components";

.pw-simple-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: rem(56) 0 rem(24);

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
        padding: rem(40) 0;
    }

    .pw-simple-content-header-logo {
        text-transform: uppercase;
        color: map-get($neutral, 10);
        margin-bottom: rem(24);

        .logo-blue {
            color: map-get($foundation, primary);
        }
    }

    .pw-simple-content-body {
        margin-bottom: rem(48);

        &.logout-variant {
            @media screen and (max-width: calc(#{rem(map-get($breakpoints, md))} - #{rem(1)})) {
                margin-bottom: rem(42);
            }
        }

        .paragraph-semi-bold {
            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                @include font-size-line-weight(32, 40);
            }
        }

        .pw-simple-container-image {
            position: relative;

            .pw-simple-content-image {
                width: auto;
                height: rem(220);
                object-fit: cover;
            }

            @include copyright-content;
        }

        .pw-simple-content-title {
            color: map-get($neutral, 10);
            margin: rem(24) 0;

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                margin: rem(24) 0 rem(16);
            }
        }

        .pw-simple-content-subtitle {
            color: map-get($neutral, 30);
            margin-bottom: rem(24);

            @media screen and (min-width: rem(map-get($breakpoints, md))) {
                margin-bottom: rem(16);
            }
        }

        .pw-simple-content-description {
            color: map-get($neutral, 30);
            margin-bottom: 0;

            &.paragraph-semi-bold-small {
                @media screen and (min-width: rem(map-get($breakpoints, md))) {
                    @include font-size-line-weight(20, 28);
                }
            }
        }
    }

    .pw-simple-content-action {
        @media screen and (min-width: rem(map-get($breakpoints, sm))) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            width: 100%;
        }

        &>* {
            width: 100%;

            @media screen and (min-width: rem(map-get($breakpoints, sm))) {
                width: auto;
            }

            &:not(:last-child) {
                @media screen and (max-width: calc(#{rem(map-get($breakpoints, sm))} - #{rem(1)})) {
                    margin-bottom: rem(8);
                }
            }
        }

        .pw-simple-content-action-button {
            @include responsive-spacing;
        }

        .pw-button-try-again {
            padding: rem(16) rem(32);
        }

        .pw-button-you-lose {
            padding: rem(16) rem(64);
        }
    }
}