@import "../../assets/scss/includes-components";

.pw-unsubscribe-confirmed-container {
  height: auto;

  .pw-simple-content-body {
    .pw-simple-content-image {
      @include unsubscribe-image-width-height;
    }

    .pw-highlight-paragraph {
      @media screen and (min-width: rem(map-get($breakpoints, md))) {
        @include font-size-line-weight(16, 24);
      }
    }
  }
}