@import "../../assets/scss/includes-components";

.pw-unsubscribe-completed-container {
  &, 
  .pw-simple-content {
    height: 100%;
  }
  
  .pw-simple-content {
    justify-content: space-between;

    @media screen and (min-width: rem(map-get($breakpoints, md))) {
      justify-content: center;
    }

    .pw-simple-content-body {
      margin-bottom: 0;

      .pw-simple-content-title {
        @media screen and (min-width: rem(map-get($breakpoints, md))) {
          margin: rem(24) 0 rem(48);
        }
      }

      .pw-simple-content-image {
        @include unsubscribe-image-width-height;
      }
    }

    .pw-simple-content-action {
      justify-content: center;

      .pw-simple-content-action-button {
        padding: rem(16) rem(76);
      }
    }
  }
}